import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header";
import Sections from "../components/sections";

export const query = graphql`
  query CustomPageTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      notificationBar {
        link {
          url
        }
      }
    }
    pageCustom: sanityPageCustom(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      description
      featuredImage {
        ...SanityImage
        alt
      }
      sections {
        _key
        title
        anchorLink
        _rawContent
        _rawContentSecondary
      }
      note
    }
  }
`;

const CustomPageTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const site = (data || {}).site;
  const pageCustom = (data || {}).pageCustom;

  return (
    <Layout>
      <Seo
        title={pageCustom.title}
        description={pageCustom.description}
        image={pageCustom.featuredImage}
      />

      {(pageCustom.title || pageCustom.sections) && <PageHeader title={pageCustom.title} notificationBar={site.notificationBar} anchors={pageCustom.sections} />}
      {pageCustom.sections && pageCustom.sections.length > 0 && (<Sections items={pageCustom.sections} note={pageCustom.note} />)}
    </Layout>
  );
};

export default CustomPageTemplate;
