import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/sections.module.css";

function Sections({ items, note }) {
  return (
    <div className={styles.root}>
      <Container>
        {items.map(({ _key, title, anchorLink, _rawContent, _rawContentSecondary }) => {
          return (
            <div className={styles.row} key={_key} id={anchorLink}>
              <h2 className={styles.title}>{title}</h2>
              <div className="grid">
                {_rawContent && (
                  <div className={cn("col-12 col-md-6", styles.col)}>
                    <div className={styles.contentLeft}>
                      <PortableText blocks={_rawContent} />
                    </div>
                  </div>
                )}
                {_rawContentSecondary && (
                  <div className={cn("col-12 col-md-6", styles.col)}>
                    <div className={styles.contentRight}>
                      <PortableText blocks={_rawContentSecondary} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {note && (<div className={styles.note}><div className={styles.noteInner}><p>{note}</p></div></div>)}
      </Container>
    </div>
  );
}

export default Sections;
